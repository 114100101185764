import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import { Container, media } from 'ui';
import { Link, graphql } from 'gatsby';
import { get } from 'lodash';
import Dotdotdot from 'react-dotdotdot';
import Hero from 'components/Hero';
import { formatDate } from 'utils';
import Seo from 'components/Seo';

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  grid-row-gap: ${({ theme }) => theme.spacing(3)};
  padding-right: 0;
  padding-left: 0;

  ${media.tablet`  grid-template-columns: repeat(2, 1fr);
  padding-right: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  `}
  ${media.desktop`  grid-template-columns: repeat(3, 1fr); padding: 0;
  `}
`;

const ArticleCardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
`;

const ArticleCardHeader = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}
    ${({ theme }) => theme.borderRadius} 0 0;

  ${({ backgroundUrl }) =>
    backgroundUrl &&
    `
    background-image: url(${backgroundUrl});
    padding-top: 56.25%;
    background-size: cover;
    background-position: center center;
    `};
`;

const ArticleCardContent = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  border-top: 0;
  padding: ${({ theme }) => theme.spacing()};
  border-radius: 0 0 ${({ theme }) => theme.borderRadius}
    ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  color: ${({ theme }) => theme.text};
  margin: 0;
  letter-spacing: 0.0375em;
  line-height: 1.5;
  flex: 1;

  h3 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.text};
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const TextLink = styled.p`
  color: ${({ theme }) => theme.secondary} !important;
  text-decoration: underline;
  line-height: 1.5;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing(0.5)}! important;
`;

const PublishedDate = styled.p`
  color: ${({ theme }) => theme.textLight} !important;
  font-size: 0.875rem;
`;

const Blog = ({ data }) => {
  const articles = get(data, 'allDatoCmsArticle.edges');
  return (
    <Layout variant="withAvocadoPattern">
      <Seo
        title="Blog"
        description="Retrouvez mes articles traitant du droit"
      />
      <Hero type="blog" />
      <Container variant="main" paddingSize="none">
        <ArticleGrid>
          {articles?.map(({ node }) => (
            <ArticleCardLink to={`/blog/${node.slug}`} key={node.slug}>
              <ArticleCardHeader backgroundUrl={node.mainPicture.url} />
              <ArticleCardContent>
                <PublishedDate>
                  {formatDate(new Date(node.meta.publishedAt), 'dd/MM/yyyy')}
                </PublishedDate>
                <h3>{node.title}</h3>
                <Dotdotdot clamp={3}>{node.kicker}</Dotdotdot>
                <TextLink>Lire l'article</TextLink>
              </ArticleCardContent>
            </ArticleCardLink>
          ))}
        </ArticleGrid>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allDatoCmsArticle(sort: { order: DESC, fields: meta___publishedAt }) {
      edges {
        node {
          title
          slug
          kicker
          content
          mainPicture {
            alt
            url
          }
          meta {
            publishedAt
          }
        }
      }
    }
  }
`;

export default Blog;
